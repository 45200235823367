// extracted by mini-css-extract-plugin
export const priceContainer = "PricingViewPlan-module--priceContainer--1Ftai";
export const isFeatured = "PricingViewPlan-module--isFeatured--31djk";
export const price = "PricingViewPlan-module--price--UL2R4";
export const amount = "PricingViewPlan-module--amount--11kr3";
export const currency = "PricingViewPlan-module--currency--1Z7Mi";
export const billingCycle = "PricingViewPlan-module--billingCycle--3RJzR";
export const billingToggle = "PricingViewPlan-module--billingToggle--1FVTc";
export const toggle = "PricingViewPlan-module--toggle--fDFsh";
export const features = "PricingViewPlan-module--features--3AWNx";
export const isIncluded = "PricingViewPlan-module--isIncluded--3v6xf";