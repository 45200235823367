import cc from 'classcat';
import React, { FC, ReactNode, useState } from 'react';
import { Heading } from 'react-bulma-components';
import * as styles from './PricingViewPlan.module.scss';

interface Feature {
  isIncluded: boolean;
  text: string;
}

type TieredPrice = [yearly: number, monthly: number];

interface PricingViewPlanProps {
  Cta: ReactNode;
  features: Feature[];
  isFeatured?: boolean;
  plan: string;
  price: TieredPrice | number;
}

const PricingViewPlan: FC<PricingViewPlanProps> = ({ Cta, features, isFeatured, plan, price }) => {
  const [visiblePrice, setVisiblePrice] = useState(typeof price === 'number' ? price : price[0]);

  const handleBillingToggle = () => {
    setVisiblePrice(previousVisiblePrice => {
      return typeof price === 'object'
        ? previousVisiblePrice === price[0]
          ? price[1]
          : price[0]
        : previousVisiblePrice;
    });
  };

  const billingCycle =
    typeof price === 'object' ? (visiblePrice === price[0] ? 'year' : 'month') : null;

  return (
    <div
      className={cc({
        [styles.priceContainer]: true,
        [styles.isFeatured]: isFeatured,
      })}
    >
      <Heading renderAs="h2" size={6}>
        {plan}
      </Heading>
      <div className={styles.price}>
        <div className={styles.amount}>
          {visiblePrice !== 0 && <span className={styles.currency}>$</span>}
          {visiblePrice !== 0 ? visiblePrice : 'Free'}
          {billingCycle && <span className={styles.billingCycle}>/ {billingCycle}</span>}
        </div>
        {typeof price === 'object' && (
          <div className={styles.billingToggle}>
            <small>Monthly</small>
            <input className={styles.toggle} onChange={handleBillingToggle} type="checkbox" />
            <small>Yearly</small>
          </div>
        )}
      </div>
      <ul className={styles.features}>
        {features.map(({ isIncluded, text }) => (
          <li key={text} className={cc({ [styles.isIncluded]: isIncluded })}>
            {text}
          </li>
        ))}
      </ul>
      {Cta}
    </div>
  );
};

export default PricingViewPlan;
