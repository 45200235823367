import PageMeta from '@components/PageMeta';
import useTranslation from '@helpers/useTranslation';
import DefaultTemplate from '@templates/DefaultTemplate';
import PricingView from '@views/PricingView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

const PricingPage: FC<PageProps> = () => {
  const { t } = useTranslation('pages');

  return (
    <DefaultTemplate>
      <PageMeta description={t('pricingDescription')} title={t('pricing')} />
      <PricingView />
    </DefaultTemplate>
  );
};

export default PricingPage;
