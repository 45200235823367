import NoWordWrap from '@components/NoWordWrap';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import { Button, Container, Content, Heading, Section } from 'react-bulma-components';
import { DASHBOARD_SUBSCRIPTION_PAGE, DOWNLOAD_PAGE } from '../../constants';
import * as styles from './PricingView.module.scss';
import PricingViewPlan from './PricingViewPlan';

const PricingView: FC = () => {
  return (
    <Section>
      <Container textAlign="center">
        <Content>
          <Heading>
            Stay focused. <NoWordWrap>Accomplish more.</NoWordWrap>
          </Heading>
          <p>Get serious about productivity with Pomello Premium. The Basic plan is always free.</p>
        </Content>
        <div className={styles.plans}>
          <PricingViewPlan
            Cta={
              <Button color="primary" fullwidth renderAs={Link} to={DOWNLOAD_PAGE} outlined>
                Get started
              </Button>
            }
            features={[
              { isIncluded: true, text: 'Unlimited boards and lists' },
              { isIncluded: true, text: 'Productivity tracking' },
              { isIncluded: true, text: 'Time tracking' },
              { isIncluded: false, text: 'Checklist items as tasks' },
              { isIncluded: false, text: 'Custom timer sounds' },
              { isIncluded: false, text: 'Export productivity data' },
            ]}
            price={0}
            plan="Basic"
          />
          <PricingViewPlan
            Cta={
              <Button
                color="primary"
                fullwidth
                renderAs={Link}
                size="medium"
                to={DASHBOARD_SUBSCRIPTION_PAGE}
              >
                Get premium
              </Button>
            }
            features={[
              { isIncluded: true, text: 'Unlimited boards and lists' },
              { isIncluded: true, text: 'Productivity tracking' },
              { isIncluded: true, text: 'Time tracking' },
              { isIncluded: true, text: 'Checklist items as tasks' },
              { isIncluded: true, text: 'Custom timer sounds' },
              { isIncluded: true, text: 'Export productivity data' },
            ]}
            isFeatured
            price={[20, 2]}
            plan="Premium"
          />
        </div>
      </Container>
    </Section>
  );
};

export default PricingView;
